import getErrorsList from '@frontend/helpers/getErrorsList'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { showToast } from 'Helpers/toast'
import { get } from 'lodash'

/**
 * Обработчик ошибок при запросах
 * @property {?} error - Ошибка
 */
export class ErrorHelper {
  constructor(error) {
    this.error = error
  }

  /**
   * @param {string} key
   * @returns {Error}
   */
  static createValidationError(key) {
    return new Error(`Invalid \`${key}\``)
  }

  handleNonHttpError() {
    if (this.isError && !this.isHttpError) {
      throw this.error
    }
  }

  get isError() {
    return this.error != null
  }

  get isHttpError() {
    return get(this.error, 'response.status') !== undefined
  }

  /**
   * Получение текста ошибки из Blob
   * @returns {Promise<string>}
   */
  async getErrorMessageFormBlob() {
    if (this.error.response.status === HttpStatusCodes.NotFound) {
      // TODO: i18n
      return 'Файл не найден'
    }

    const blobContent = await this.error.response.data.text()

    return JSON.parse(blobContent).message
  }

  getErrorMessage() {
    return this.error.response?.data?.message ?? 'Неизвестная ошибка'
  }

  getErrorMessageList() {
    return this.error.response?.data
      ? getErrorsList(this.error)
      : 'Неизвестная ошибка'
  }

  processError() {
    this.handleNonHttpError()

    showToast(this.getErrorMessage(), 'error')
  }
}
